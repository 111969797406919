import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const Icon = props => {
	const {
		image,
		hover,
		url,
		onMouseLeave,
		onMouseEnter,
		imagePng
	} = props;
	return (
		<img 
			src={imagePng} 
			className={`round-icon ${hover ? image + "-hover" : null}`}
			onMouseEnter={()=>onMouseEnter(image)} 
			onMouseLeave={onMouseLeave}
			onClick={() => window ? window.location.assign(url) : null}
		/>
	);
};

Icon.propTypes = {
	image: PropTypes.string,
	hover: PropTypes.string,
	url: PropTypes.string,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	imagePng: PropTypes.any
};

export default Icon;