import Logo from "../../assets/svg/logo.js";
import SiteIcons from "../SiteIcons";
import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import "./styles.css";

const Splash = props => {
	const [partialVisibility, setPartialVisibility] = useState(true);

    return (
        <>
            <Helmet>
                <title>Gilbert Lam</title>
            </Helmet>
            <VisibilitySensor
                partialVisibility={partialVisibility}
                offset={partialVisibility ? { bottom: 10 } : {}}
                onChange={inView => {
                    if (document && inView) {
                        document.body.style = `background: ${props.background}`;
                        setPartialVisibility(false);
                    }
                }}
            >
                <div className="splash">
                    <div className="splashContainer">
                        <Logo className="logo" />
                        <SiteIcons />
                    </div>
                </div>
            </VisibilitySensor>
        </>
    );
};

Splash.propTypes = {
    background: PropTypes.string,
};

export default Splash;
