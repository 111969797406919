import React, { useEffect } from "react";
import Splash from "../containers/Splash";
import Resume from "../containers/Resume";
import Project from "../containers/Project";
import Project1Image from "../assets/images/project1lap.png";
import Project2Image from "../assets/images/project2lap.png";
import Project3Image from "../assets/images/project3iphone.png";
import scrollSnapPolyfill from "css-scroll-snap-polyfill";
import ReactGA from "react-ga";
import { useMediaQuery } from "react-responsive";
import "./styles.css";

const Home = () => {
    useEffect(() => {
        ReactGA.initialize("UA-56245575-3");
        ReactGA.pageview("/home");
        scrollSnapPolyfill();
    }, []);

    const isMobile = useMediaQuery({
        query: "(max-device-width: 1024px)",
    });

    return (
        <div className="site">
            <section className="home">
                <Splash background="#1d1d1d" />
            </section>

            {/* <section className="resume-page">
                <Resume background="#1d1d1d" />
            </section>
            <section className="project-three">
                <Project
                    project={{
                        title: "MakeTwitterPositiveAgain",
                        image: Project3Image,
                        subtitle: "Progressive Web App (Work in progress!)",
                        description: [
                            "Retrieves user's tweets using the Twitter API",
                            "Determines the sentiment of each tweet with Natural Language API",
                            "Shows the scores of tweets and allows users to delete tweets",
                            "Planned Feature: Better design for desktop mode",
                            "Planned Feature: Show trends of the user's tweets over time",
                            "Planned Feature: Store Natural Language API results in DynamoDB to save costs",
                        ],
                        stack: [
                            "ReactJS",
                            "NodeJS",
                            "AWS CDK",
                            "API Gateway",
                            "AWS Lambda",
                            "Google Cloud Natural Language API",
                            "Twitter API",
                        ],
                        url: "https://twitter.gilbertlam.me",
                    }}
                    background="#1fa1f1"
                />
            </section>

            <section className="project-one">
                <Project
                    imageLeft={isMobile ? false : true}
                    project={{
                        title: "SPOTIFY LYRICS",
                        image: Project1Image,
                        subtitle: "Automatically retrieve lyric information",
                        description: [
                            "Progressive Web App",
                            "Connects to your Spotify account to show lyrics of current song",
                            "Automatically updates when song changes",
                            "Allows user to control playback",
                        ],
                        stack: [
                            "ReactJS",
                            "NodeJS",
                            "AWS CDK",
                            "API Gateway",
                            "AWS Lambda",
                            "IBM Watson Natural Language API",
                            "Spotify API",
                        ],
                        url: "https://spotify.gilbertlam.me",
                    }}
                    background="#628d2d"
                />
            </section>

            <section className="project-two">
                <Project
                    project={{
                        title: "DOTA BEHAVIOUR",
                        image: Project2Image,
                        subtitle: "Analyze what you say in game",
                        description: [
                            "Retrieves a player's chat logs using their Steam ID",
                            "Determines the sentiment of messages with Natural Language API",
                            "Shows the most positive and most negative message, and more!",
                        ],
                        stack: [
                            "NodeJS",
                            "OpenDOTA API",
                            "ReactJS",
                            "OAuth 2.0",
                            "Google Natural Language API",
                            "Firebase",
                        ],
                        url: "https://github.com/GilbertHLam/Dota2-Behaviour",
                    }}
                    background="#b23e27"
                />
            </section> */}
        </div>
    );
};

export default Home;
