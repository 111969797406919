import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { useMediaQuery } from "react-responsive";

const Logo = (props) => {
    const isMobile = useMediaQuery({
        query: "(max-device-width: 1024px)",
    });
    return (
        <>
            <svg className={props.className}>
                <defs>
                    <filter id="glow">
                        <feGaussianBlur stdDeviation="6" result="coloredBlur" />
                        <feMerge>
                            <feMergeNode in="coloredBlur" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <filter
                        id="neon_glow"
                        x="-50%"
                        y="-50%"
                        width="200%"
                        height="200%"
                        filterUnits="userSpaceOnUse"
                    >
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
                    </filter>
                    <filter
                        id="static_blur"
                        x="-50%"
                        y="-50%"
                        width="200%"
                        height="200%"
                        filterUnits="userSpaceOnUse"
                    >
                        <feGaussianBlur in="SourceGraphic" stdDeviation="1" />
                    </filter>
                </defs>
                {isMobile ? (
                    <symbol id="s-text">
                        <text textAnchor="start" x="8%" y="55%" dy=".35em">
                            {"GILBERT"}
                        </text>
                    </symbol>
                ) : (
                    <symbol id="s-text">
                        <text textAnchor="middle" x="50%" y="50%" dy=".35em">
                            {"GILBERT LAM"}
                        </text>
                    </symbol>
                )}

                <use className="text" href="#s-text"></use>
                <use className="text" href="#s-text"></use>
                <use className="text" href="#s-text"></use>
                <use className="text" href="#s-text"></use>
                <use className="text" href="#s-text"></use>
            </svg>
            {isMobile ? (
                <svg className={props.className + " mb"}>
                    <defs>
                        <filter id="glow">
                            <feGaussianBlur
                                stdDeviation="6"
                                result="coloredBlur"
                            />
                            <feMerge>
                                <feMergeNode in="coloredBlur" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                        <filter
                            id="neon_glow"
                            x="-50%"
                            y="-50%"
                            width="200%"
                            height="200%"
                            filterUnits="userSpaceOnUse"
                        >
                            <feGaussianBlur
                                in="SourceGraphic"
                                stdDeviation="10"
                            />
                        </filter>
                        <filter
                            id="static_blur"
                            x="-50%"
                            y="-50%"
                            width="200%"
                            height="200%"
                            filterUnits="userSpaceOnUse"
                        >
                            <feGaussianBlur
                                in="SourceGraphic"
                                stdDeviation="1"
                            />
                        </filter>
                    </defs>
                    <symbol id="s-text1">
                        <text textAnchor="start" x="10%" y="20%" dy=".35em">
                            {"LAM"}
                        </text>
                    </symbol>
                    <use
                        text-anchor="start"
                        x="0"
                        y="0"
                        className="text mobile"
                        href="#s-text1"
                    ></use>
                    <use
                        text-anchor="start"
                        x="0"
                        y="0"
                        className="text mobile"
                        href="#s-text1"
                    ></use>
                    <use
                        text-anchor="start"
                        x="0"
                        y="0"
                        className="text mobile"
                        href="#s-text1"
                    ></use>
                    <use
                        text-anchor="start"
                        x="0"
                        y="0"
                        className="text mobile"
                        href="#s-text1"
                    ></use>
                    <use
                        text-anchor="start"
                        x="0"
                        y="0"
                        className="text mobile"
                        href="#s-text1"
                    ></use>
                </svg>
            ) : null}
        </>
    );
};

Logo.propTypes = {
    className: PropTypes.string,
};

export default Logo;
