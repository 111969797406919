import React, { Component } from "react";
import Icon from "../../components/Icon";
import Pdf from "../../assets/documents/RESUME.pdf";
import GithubIcon from "../../assets/images/githubLit.png";
import LinkedInIcon from "../../assets/images/linkedinLit.png";
import PhoneIcon from "../../assets/images/phoneLit.png";
import EmailIcon from "../../assets/images/emailLit.png";
import "./styles.css";

class SiteIcons extends Component {
    constructor(props) {
        super(props);
        this.state = { hovered: null };
    }

    onMouseEnter(site) {
        this.setState({ hovered: site });
    }

    onMouseLeave() {
        this.setState({ hovered: null });
    }

    render() {
        const sites = [
            {
                site: "github",
                link: "https://github.com/GilbertHLam",
                imagePng: GithubIcon,
            },
            {
                site: "email",
                link: "mailto:gilberthlam@cmail.carleton.ca",
                imagePng: EmailIcon,
            },
            //{site:"phone", link:Pdf, imagePng: PhoneIcon},
            {
                site: "linkedin",
                link: "https://linkedin.com/in/gilberthlam",
                imagePng: LinkedInIcon,
            },
        ];

        return (
            <div className="container">
                <div className="icons">
                    {sites.map((site) => (
                        <Icon
                            url={site.link}
                            image={site.site}
                            imagePng={site.imagePng}
                            onMouseEnter={(site) => this.onMouseEnter(site)}
                            onMouseLeave={() => this.onMouseLeave()}
                            hover={site.site === this.state.hovered}
                            key={0}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default SiteIcons;
