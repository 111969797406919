import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../../components/Button";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";
import "./styles.css";

const Project = props => {
	const { imageLeft, project, background } = props;
	const [partialVisibility, setPartialVisibility] = useState(true);

	const projectDescription = (
		<div className={`project-description ${!imageLeft ? "nudge" : ""}`}>
			<Fade bottom>
				<h1 className={project.title === "MakeTwitterPositiveAgain" ? "small-font" : ""}>{project.title}</h1>
				<h2>{project.subtitle}</h2>

				<ul>
					{project.description.map(item => {
						return <li key={item}>{item}</li>;
					})}
				</ul>
				<h3>{"Made with:"}</h3>
				<ul>
					{project.stack.map(item => {
						return <li key={item}>{item}</li>;
					})}
				</ul>
			</Fade>
		</div>
	);

	const projectImage = (
		<div className="project-image">
			<img className={project.title === "MakeTwitterPositiveAgain" ? "iphone" : "laptop"} src={project.image} />
			<Button text="Try it here" url={project.url} />
		</div>
	);

	return (
		<VisibilitySensor
			partialVisibility={partialVisibility}
			offset={partialVisibility ? { bottom: 10 } : {}}
			onChange={inView => {
				if (inView && document) {
					document.body.style = `background: ${background}`;
					setPartialVisibility(false);
				}
			}}
		>
			<div className="project">
				<div className="item">
					{imageLeft ? projectImage : projectDescription}
				</div>
				<div className="right item">
					{imageLeft ? projectDescription : projectImage}
				</div>
			</div>
		</VisibilitySensor>
	);
};

Project.propTypes = {
	imageLeft: PropTypes.bool,
	project: PropTypes.object,
	background: PropTypes.string,
};

export default Project;
